const pjson = require('../package.json');
let config;

if (process.env.REACT_APP_ENV === 'development-local') {
	config = {
		host: 'http://localhost:3000',
		host2: 'http://localhost:8080',
		api: 'http://localhost:8086',
		apiv1: 'http://localhost:8086/v1',
		auth: 'http://localhost:3006',
		dentallyAPI: 'https://api.sandbox.dentally.co',
		pconAPI: 'https://tconwebapi-dev.azurewebsites.net',
		pconHUB: 'https://tconhub-dev.azurewebsites.net',
		dentallyID: 'qKVNCdpLw4DPZ83HcIzihOwPUg_Gw-HO1gZ9YKYRevU',
		dentallySECRET: 'FLkYji4T_fzvmU5qf5fn5Y2SFOP5qqsy89-DhuXh8Rw',
		onesignalAppId: '91daa3d1-b4d5-48d2-9bfd-592e578128eb',
		apiKeyMaps: process.env.REACT_APP_API_KEY_MAPS_DEV,
		pjson: `Local development server version ${pjson.version}`,
	};
} else if (process.env.REACT_APP_ENV === 'development') {
	config = {
		host: 'https://procuur-dev.com',
		api: 'https://api.procuur-dev.com',
		apiv1: 'https://api.procuur-dev.com/v1',
		auth: 'https://accounts.procuur-dev.com',
		dentallyAPI: 'https://api.sandbox.dentally.co',
		pconAPI: 'https://tconwebapip-dev.azurewebsites.net',
		pconHUB: 'https://tconhubp-dev.azurewebsites.net',
		dentallyID: 'qKVNCdpLw4DPZ83HcIzihOwPUg_Gw-HO1gZ9YKYRevU',
		dentallySECRET: 'FLkYji4T_fzvmU5qf5fn5Y2SFOP5qqsy89-DhuXh8Rw',
		onesignalAppId: '5a94f8b7-40c7-44cb-a48b-78bf7d7c230f',
		apiKeyMaps: process.env.REACT_APP_API_KEY_MAPS,
		pjson: `Live development server version ${pjson.version}`,
	};
} else if (process.env.REACT_APP_ENV === 'staging') {
	config = {
		host: 'https://procuur-staging.com',
		api: 'https://api.procuur-staging.com',
		apiv1: 'https://api.procuur-staging.com/v1',
		auth: 'https://accounts.procuur-staging.com',
		dentallyAPI: 'https://api.sandbox.dentally.co',
		pconAPI: 'https://tconwebapi-stg.azurewebsites.net',
		pconHUB: 'https://tconhub-stg.azurewebsites.net',
		dentallyID: 'dbZY7Z2mmsV9_0lOrZKJRIahaGZHZMcEaTdShH07cNc',
		dentallySECRET: 'ZLqcJrm-RlRSXUZTNrtuOo_SFKJxjjCDq6qKl6MU_rs',
		onesignalAppId: 'c1de9749-96b9-4c49-b63d-83fe288917fe',
		apiKeyMaps: process.env.REACT_APP_API_KEY_MAPS,
		pjson: `Live staging server version ${pjson.version}`,
	};
} else if (process.env.REACT_APP_ENV === 'production') {
	config = {
		host: 'https://procuur.com',
		api: 'https://api.procuur.com',
		apiv1: 'https://api.procuur.com/v1',
		auth: 'https://accounts.procuur.com',
		dentallyAPI: 'https://api.dentally.co',
		pconAPI: 'https://tconwebapi.azurewebsites.net',
		pconHUB: 'https://tconhub.azurewebsites.net',
		dentallyID: 'dbZY7Z2mmsV9_0lOrZKJRIahaGZHZMcEaTdShH07cNc',
		dentallySECRET: 'ZLqcJrm-RlRSXUZTNrtuOo_SFKJxjjCDq6qKl6MU_rs',
		onesignalAppId: '3026e63b-43e6-4206-a747-caaa4bb016bc',
		apiKeyMaps: process.env.REACT_APP_API_KEY_MAPS,
	};
} else {
	config = {
		host: 'http://localhost:3000',
		host2: 'http://localhost:8080',
		api: 'http://localhost:8086',
		apiv1: 'http://localhost:8086/v1',
		auth: 'http://localhost:3006',
		dentallyAPI: 'https://api.sandbox.dentally.co',
		pconAPI: 'https://tconwebapi-dev.azurewebsites.net',
		pconHUB: 'https://tconhub-dev.azurewebsites.net',
		dentallyID: 'qKVNCdpLw4DPZ83HcIzihOwPUg_Gw-HO1gZ9YKYRevU',
		dentallySECRET: 'FLkYji4T_fzvmU5qf5fn5Y2SFOP5qqsy89-DhuXh8Rw',
		onesignalAppId: '91daa3d1-b4d5-48d2-9bfd-592e578128eb',
		apiKeyMaps: process.env.REACT_APP_API_KEY_MAPS_DEV,
		pjson: `Local development server version ${pjson.version}`,
	};
}

export default config;
